import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['modal'];

  sendEvent(jobId, actionType) {
    // プレビュー時はnullになる
    if (!jobId || !actionType) return;

    $.post('/phone_calls', {
      'phone_call[job_id]': jobId,
      'phone_call[action_type]': actionType,
    }).done(phoneEntry => {
      if (phoneEntry.action_type === 'click') return;

      this.phoneEntry = phoneEntry;
    });
  }

  sendDuration(duration) {
    const url = `/phone_calls/${this.phoneEntry.id}`;
    const data = { 'phone_call[duration]': duration };
    const type = 'PUT';

    $.ajax({ url, data, type });
  }

  trackingCallListener = () => {
    const endAt = new Date().getTime();
    const waitTime = 5; // FIXME どのくらいの時間を計測ボーダにするのか

    const duration = (endAt - this.startAt) / 1000;
    if (duration > waitTime) {
      this.sendDuration(duration);
    }

    document.removeEventListener('touchstart', this.trackingCallListener);
    document.removeEventListener('mousemove', this.trackingCallListener);
  };

  callBtnClick() {
    this.startAt = new Date().getTime();

    document.addEventListener('touchstart', this.trackingCallListener);
    document.addEventListener('mousemove', this.trackingCallListener);

    this.sendEvent(this.data.get('job-id'), 'call');
  }

  openModal(e) {
    e.preventDefault();
    const $modal = $(this.modalTarget);
    $modal.show();

    // モーダルの位置が上下中央になるように調整
    const $inner = $modal.find('.inner');
    const innerHeight = $inner.outerHeight();
    $inner.css('margin-top', `-${innerHeight / 2}px`);

    this.sendEvent(this.data.get('job-id'), 'click');
  }

  closeModal() {
    document.removeEventListener('touchstart', this.trackingCallListener);
    document.removeEventListener('mousemove', this.trackingCallListener);
    $(this.modalTarget).hide();
  }
}
