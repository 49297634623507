$(() => {
  if (!$('.js-search-condition-modal').exists()) {
    return;
  }

  $('.js-search-condition-modal-toggle').on('click', e => {
    const $toggle = $(e.currentTarget);
    const $target = $($toggle.data('target'));
    const $modal = $target.find('.content_inner');
    const $except = $($toggle.data('except'));

    // 同時に選択すべきじゃない検索条件（駅と都市とか）をリセットする
    if ($except.exists()) {
      $except.find('input[type="checkbox"]').prop('checked', false);
    }

    $target.show();
    $('html').css('overflow', 'hidden');

    // overflow: auto をするために高さの定義が必要なので、
    // 最初にモーダルを開いたときのサイズでスクロールエリアの高さを固定する
    $target.find('.content_body').css({
      height: `${$modal.height()}px`,
    });

    // モーダルを開いた状態でウィンドウサイズを変更したとき
    // モーダルの中身がはみ出てしまうため、最初に開いたときのサイズでモーダル全体の高さも固定する
    $modal.css({
      height: `${$modal.innerHeight()}px`,
    });
  });

  $('.js-modal-lay').on('click', e => {
    $(e.currentTarget).closest('.js-search-condition-modal').hide();
    $('html').css('overflow', 'auto');
  });
});
