export default class SearchCountupper {
  constructor(element, to) {
    this.element = element;
    this.to = to;
    this.num = parseInt(this.element.text().trim(), 10);
  }

  changeNum() {
    const diff = this.to - this.num;
    const abs = Math.abs(diff);
    let step;

    if (abs >= 3000) {
      step = 800;
    } else if (abs >= 1500 && abs < 3000) {
      step = 500;
    } else if (abs >= 500 && abs < 1500) {
      step = 100;
    } else if (abs > 30 && abs < 500) {
      step = 30;
    } else {
      step = 1;
    }

    return diff > 0 ? step : -step;
  }

  countup() {
    if (this.num !== this.to) {
      this.num += this.changeNum();
      this.element.html(this.num);
      setTimeout(() => this.countup(), 10);
    }
  }
}
