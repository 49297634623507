import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['salaryType', 'hourlyPay', 'daylyPay', 'monthlyPay'];

  connect() {
    this.switchSelects();
  }

  switchSelects() {
    const payTargetMap = [
      ['0', 'hourlyPayTarget'],
      ['1', 'daylyPayTarget'],
      ['2', 'monthlyPayTarget'],
    ];

    for (let i = 0; i < payTargetMap.length; i += 1) {
      const typeId = payTargetMap[i][0];
      const target = payTargetMap[i][1];

      if (this.salaryTypeTarget.value === typeId) {
        this[target].querySelector('select').disabled = false;
        this[target].style.display = '';
      } else {
        this[target].querySelector('select').disabled = true;
        this[target].style.display = 'none';
      }
    }
  }
}
