// モーダルの表示制御に関する機能です。
// 表示非表示は共通化し、各画面特有のモーダルは独自のCSSクラスを定義して制御するようにしています。
//
// Example
//
// <a href="#" class="js-modal-toggle" data-target=".js-modal-1">
//   modal toggle
// </a>
//
// <div class="js-modal js-modal1">modal content</div>
//

import initializeShopMap from '../../lib/shop_map';

// ##### 共通系:表示処理
$('.js-modal-toggle').on('click', e => {
  const $target = $($(e.currentTarget).data('target'));
  if ($target.dialog('isOpen')) {
    $target.dialog('close');
  } else {
    $target.dialog('open');
  }
});

// ##### 共通系:非表示処理
$(document).on('click', '.ui-widget-overlay', () => $('.ui-dialog-content').dialog('close'));

// ##### 勤務地の地図モーダル
if ($('.js-map-modal').length > 0) {
  // 地図は初期開示時にレンダリングするのでそのフラグ
  let mapInitialized = false;

  $('.js-map-modal').dialog({
    autoOpen: false,
    height: 600,
    width: 900,
    modal: true,
    open() {
      // レンダリング済みなら処理しない
      if (mapInitialized) {
        return;
      }
      mapInitialized = true;

      $('.js-shop-map').width('100%').height('100%');
      initializeShopMap($('.js-shop-map'));
    },
  });
}
