$('.js-validate-entry').validate({
  rules: {
    'entry[name]': {
      required: true,
    },
    'entry[kana]': {
      required: true,
    },
    'entry[email]': {
      required: true,
    },
    'entry[phone_number]': {
      required: true,
    },
    'entry[birthday(1i)]': {
      required: true,
    },
    'entry[birthday(2i)]': {
      required: true,
    },
    'entry[birthday(3i)]': {
      required: true,
    },
    'entry[gender]': {
      required: true,
    },
    'entry[current_occupation]': {
      required: true,
    },
    'entry[entry_requirement_ids][]': {
      required: true,
      minlength: 1,
    },
    'entry[remark]': {
      maxlength: 200,
    },
    'entry[question_answer]': {
      required: true,
      maxlength: 1000,
    },
  },

  groups: {
    birthday: 'entry[birthday(1i)] entry[birthday(2i)] entry[birthday(3i)]',
  },
});

$(() => {
  // 備考のテキストエリアをクリックすると大きくなるように
  $('.js-textarea').click(e => $(e.currentTarget).css({ height: 'auto' }));
});
