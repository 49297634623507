$.extend($.validator.messages, {
  required: 'この欄は必ずご入力ください',
  email: 'Eメールアドレスが正しくありません',
});

$.validator.setDefaults({
  onclick: false,
  onfocusout(element) {
    $(element).valid();
  },
});
