$.validator.setDefaults({
  errorClass: 'feedback-ng',
  validClass: 'feedback-ok',

  // inputの後ろではなく、直上の%div.inputの後ろにエラーラベルを追加する。
  // 個別の調整が必要な場合は個々のruleで上書きして下さい。
  errorPlacement($error, $element) {
    $error.insertAfter($element.closest('.input'));
  },
});
