$(() => {
  $('.js-accordion').on('click', e => {
    const $accordion = $(e.currentTarget);
    const $target = $($accordion.data('target'));
    if ($target.is(':visible')) {
      $accordion.removeClass('active');
    } else {
      $accordion.addClass('active');
    }
    $target.toggle();
  });
});
