$('.js-validate-customer-contact').validate({
  rules: {
    'customer_contact[contact_type]': {
      required: true,
    },
    'customer_contact[company_name]': {
      required: true,
      maxlength: 255,
    },
    'customer_contact[department]': {
      maxlength: 255,
    },
    'customer_contact[name]': {
      required: true,
      maxlength: 255,
    },
    'customer_contact[email]': {
      required: true,
      maxlength: 255,
    },
    'customer_contact[phone_number]': {
      required: true,
      maxlength: 255,
    },
    'customer_contact[message]': {
      maxlength: 2000,
    },
  },
});
