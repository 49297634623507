import SearchCountupper from './search_countupper';

export default class SearchConditionForm {
  constructor() {
    this.$form = $('.js-search-condition-form');
    this.prepare();
  }

  prepare = () => {
    this.$form.on('change', 'input, select', e => {
      const hasParentCategory = $(e.currentTarget).data('parentCategory');
      const isParentCategory = ['line_ids', 'occupation_category_ids', 'city_group_id'].some(val =>
        e.currentTarget.id.includes(val)
      );

      if (hasParentCategory) this.changeParentCheckStateByChild(e, hasParentCategory);
      if (isParentCategory) this.changeChildCheckStateByParent($(e.currentTarget));

      this.countUp();
    });
  };

  $childSelectors = parentId => $(`input:not(:disabled)[data-parent-id=${parentId}]`).length;

  $checkedChildSelectors = parentId => $(`input:not(:disabled)[data-parent-id=${parentId}]:checked`).length;

  $checkedParentSelectors = () => this.$form.find('input[data-parent-key]:checked');

  changeChildCheckStateByAllCheckedParents = () => {
    this.$checkedParentSelectors().each((_, parentSelector) => {
      this.changeChildCheckStateByParent($(parentSelector));
    });
  };

  changeChildCheckStateByParent = $parent => {
    const parentId = $parent.val();
    $(`input:not(:disabled)[data-parent-id=${parentId}]`).prop('checked', $parent.is(':checked'));
  };

  changeParentCheckStateByChild = (event, parentCategory) => {
    const parentId = $(event.currentTarget).data('parent-id');
    const $parentSelector = $(`input[id=q_${parentCategory}_${parentId}]`);

    const allChildChecked = this.$childSelectors(parentId) === this.$checkedChildSelectors(parentId);

    $parentSelector.prop('checked', allChildChecked);
  };

  countUp() {
    $.get(this.$form.data('count-url'), this.$form.serializeArray(), 'json').done(res =>
      this.$form.find('.js-count').each((_, e) => new SearchCountupper($(e), res.count).countup())
    );
  }
}
