import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['regions', 'prefectures'];

  showRegions(e) {
    e.preventDefault();

    $(this.selectedPrefectures).fadeOut({
      duration: 200,
      complete: () => {
        $(this.regionsTarget).fadeIn(100);
      },
    });
  }

  selectRegion(e) {
    e.preventDefault();

    const regionId = $(e.target).data('region-id');
    this.selectedPrefectures = this.prefecturesTargets.find(t => $(t).data('region-id') === regionId);

    $(this.regionsTarget).fadeOut({
      duration: 200,
      complete: () => {
        $(this.selectedPrefectures).fadeIn(100);
      },
    });
  }
}
