import SearchConditionForm from '../lib/search_condition_form';

$(() => {
  $('.js-search-result-submitter').on('submit', () => {
    const searchConditionForm = new SearchConditionForm();
    searchConditionForm.$checkedParentSelectors().each((_, e) => {
      const parentId = $(e).val();
      const $childCategorySelectors = $('.js-search-condition-form').find(`input:checked[data-parent-id=${parentId}]`);
      $childCategorySelectors.prop('disabled', 'disabled');
    });
  });
});
