$(() => {
  if ($('.js-photo-stacks').length === 0) {
    return;
  }

  $('.js-photo-stacks').each((_, el) => {
    const $stacks = $(el).find('.js-stack');
    const $photoCaption = $(el).find('.js-caption');
    const $photoContainerImg = $(el).find('.js-photo img');

    $(el)
      .find('.js-stack')
      .on('mouseenter', e => {
        const $stack = $(e.currentTarget);
        if ($stack.hasClass('active')) {
          return;
        }

        $stacks.removeClass('active');
        $stack.addClass('active');

        $photoCaption.html($stack.data('caption'));
        $photoContainerImg.attr('src', $stack.data('photo'));
      });
  });
});
