$(() => {
  $('.js-toggle-favorite').click(e => {
    e.preventDefault();

    const $toggleFavorite = $(e.currentTarget);
    const toggleUrl = $toggleFavorite.data('toggle-url');
    if (!toggleUrl) return;

    const jobId = $(e.currentTarget).data('job-id');

    $.post(toggleUrl).done(data => {
      const $button = $(`.js-toggle-favorite[data-job-id='${jobId}']`);
      const $buttonText = $button.find('.js-favorite-text');
      const $headerFavorite = $('.js-header-favorite');
      const $count = $('.js-favorite-count');
      const favored = $toggleFavorite.hasClass('favored');

      if (favored) {
        $button.removeClass('favored');
        $buttonText.html('お気に入りに追加');
      } else {
        $button.addClass('favored');
        $buttonText.html('お気に入りに追加済み');

        // headerのお気に入りマークがグレーだったら、ピンクにする
        if (!$headerFavorite.hasClass('favor')) {
          $headerFavorite.addClass('favor');
          $('.js-header-menu').find($headerFavorite).after("<div class='js-favorite-count count'>1</div>");
        }
      }

      // お気に入り数のrender
      $count.html(data.count);
    });
  });
});
