import SearchConditionForm from '../../lib/search_condition_form';

$(() => {
  const searchConditionForm = new SearchConditionForm();
  if (!searchConditionForm.$form.exists()) return;

  // 親カテゴリにチェックがある際は子カテゴリを検索パラメータに含まないためcheckが外れてしまう。
  // 再描画前に子カテゴリのCheckをつける処理が必要なためここで処理をする
  searchConditionForm.changeChildCheckStateByAllCheckedParents();
});
