$(() => {
  const currentPrefectureId = $('.js-top-search-map').data('current-prefecture-id');
  $(`.js-prefecture-${currentPrefectureId}`).attr('class', `js-prefecture-${currentPrefectureId} active`);

  $('.js-top-regions svg a').click(e => {
    e.preventDefault();
    const regionId = $(e.currentTarget).data('region-id');
    $('.js-top-regions').hide();
    $('.js-top-prefectures').show();
    $(`.js-top-prefecture-${regionId}`).show();
  });

  $('.js-button-regions').click(() => {
    $('.js-top-prefectures').hide();
    $('.js-top-prefecture').hide();
    $('.js-top-regions').show();
  });
});
