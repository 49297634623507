$('.js-validate-contact').validate({
  rules: {
    'contact[contact_type]': {
      required: true,
    },
    'contact[name]': {
      required: true,
      maxlength: 255,
    },
    'contact[email]': {
      required: true,
      maxlength: 255,
    },
    'contact[message]': {
      required: true,
      maxlength: 2000,
    },
  },
});
