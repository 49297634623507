import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['modal', 'content'];

  connect() {
    this.modalTarget.addEventListener('click', () => {
      this.close();
    });
    this.contentTarget.addEventListener('click', e => {
      e.stopPropagation();
    });
  }

  open() {
    $(this.modalTarget).fadeIn();
    this.contentTarget.style.marginTop = `-${this.contentTarget.offsetHeight / 2}px`;
  }

  close() {
    $(this.modalTarget).fadeOut();
  }
}
