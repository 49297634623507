import { Controller } from 'stimulus';

export default class extends Controller {
  send() {
    const category = this.data.get('category');
    const options = JSON.parse(this.data.get('options'));

    $.post('/event_logs', JSON.stringify({ category, options }));
  }
}
