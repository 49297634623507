// ページから離脱する時に表示させるアラート
// viewの頭に`- provide :body_classes, "js-beforeunload-enable"`を記述すると動きます

if ($('body').hasClass('js-beforeunload-enable')) {
  // TODO スマホ用
  const leaveAlert = e => {
    e.returnValue = '';
    return e.returnValue;
  };

  $(window).on('beforeunload', leaveAlert);
  $('form').on('submit', () => {
    $(window).off('beforeunload', leaveAlert);
  });
}
