$(() => {
  const $form = $('.js-submit-handler');
  if (!$form.exists()) return;

  const $submit = $('input[type=submit]:not([name=back])', $form);
  const validLabel = $submit.data('valid-label') || $submit.val();
  const invalidLabel = $submit.data('invalid-label');

  function checkForm() {
    const formValidate = $form.validate();
    const valid = formValidate && formValidate.checkForm();

    if (valid) {
      $submit.prop('disabled', false);
      $submit.removeClass('disabled');
      $submit.val(validLabel);
    } else {
      $submit.prop('disabled', 'disabled');
      $submit.addClass('disabled');
      $submit.val(invalidLabel);
    }
  }

  // 全てvalidになったらsubmitボタンを有効にする
  checkForm();
  $form.on('blur keyup change touchstart', () => {
    // すでにsubmitされている場合は実行しない
    // submitした後に実行すると、submitボタンのdisabledが取れて、二重送信できてしまうため
    if ($form.hasClass('submitted')) return;
    checkForm();
  });

  $form.on('submit', () => {
    $form.addClass('submitted');
  });
});
