function setMarkerToShopMap(map) {
  const marker = new google.maps.Marker({
    position: map.getCenter(),
    icon: 'https://www.google.com/mapfiles/gadget/arrowSmall80.png',
    draggable: false,
  });

  marker.setMap(map);
}

export default function initializeShopMap(mapContainer) {
  const map = new google.maps.Map(mapContainer[0], {
    zoom: 17,
    center: { lat: mapContainer.data('latitude'), lng: mapContainer.data('longitude') },
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    scrollwheel: false,
    mapTypeControl: false,
  });

  setMarkerToShopMap(map);
}
