$('.js-validate-new-password-reset').validate({
  rules: {
    'password_reset[email]': {
      required: true,
    },
  },
});

$('.js-validate-edit-password-reset').validate({
  rules: {
    'user[password]': {
      required: true,
    },
    'user[password_confirmation]': {
      required: true,
    },
  },
});
