$('.js-validate-company').validate({
  rules: {
    'company[accounts_attributes][0][email]': {
      required: true,
    },
    'company[accounts_attributes][0][name]': {
      required: true,
    },
    'company[sales_phone_number]': {
      required: true,
    },
    'company[name]': {
      required: true,
    },
  },
});
