$('.js-validate-session').validate({
  rules: {
    'session[email]': {
      required: true,
    },
    'session[password]': {
      required: true,
    },
  },
});
