$('.js-validate-new-user').validate({
  rules: {
    'user[email]': {
      required: true,
    },
    'user[password]': {
      required: true,
    },
  },
});

$('.js-validate-edit-user').validate({
  rules: {
    'user[email]': {
      required: true,
    },
  },
});
