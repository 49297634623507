$(() => {
  $('.js-omitted').readmore({
    speed: 200,
    collapsedHeight: 84,
    moreLink: '<a href="#" class="readmore">続きを読む<span class="icn icn_cp icf_arrow_down"></span></a>',
    lessLink: '',
  });

  $('.js-entry-job-omitted').readmore({
    speed: 200,
    collapsedHeight: 20,
    moreLink: '<a href="#" class="open"><span class="icn icn_cp icf_arrow_down"></span></a>',
    lessLink: '<a href="#" class="open"><span class="icn icn_cp icf_arrow_up"></span></a>',
  });
});
