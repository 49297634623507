/* eslint import/newline-after-import:0 import/first:0 */
import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '../../../initializers/polyfill_closest';

import 'jquery-ui/ui/widgets/dialog';
import 'jquery-ujs';
import 'jquery-validation/dist/jquery.validate';
import 'jquery-validation/dist/localization/messages_ja';
import 'slick-carousel';
import '../../../vendor/jquery.autoKana';
import '../../../vendor/readmore';

import '../../../initializers/validates';
import '../../../initializers/exists';
import '../../../initializers/prevent_enter_submit';
import '../../../initializers/submit_handler';
import '../../../initializers/autokana';

import 'normalize.css/normalize.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'jquery-ui/themes/base/dialog.css';
import '../../../symbols/style.css';
import '../../../joblist/pc/stylesheets/application.css';

const requireAll = requireContext => requireContext.keys().map(requireContext);
requireAll(require.context('../../../joblist/pc/src', true, /.js$/));
requireAll(require.context('../../../joblist/common', true, /.js$/));

const application = Application.start();
const context = require.context('../../../joblist/controllers', true, /.js$/);
application.load(definitionsFromContext(context));
