$(() => {
  $('.carousel_thumbnail').hover(e => {
    e.preventDefault();
    const $thumbnail = $(e.currentTarget);
    const url = $thumbnail.data('url');
    const caption = $thumbnail.data('caption') || '';
    const $carousel = $thumbnail.closest('.carousel');
    $carousel.find('.carousel_thumbnail').removeClass('selected');
    $thumbnail.addClass('selected');

    $carousel.find('.carousel_main_photo img').attr('src', url);
    $carousel.find('.carousel_caption').html(caption);
  });
});
