$(() => {
  const setupCarousel = ($el, isAutoCarousel = null) => {
    const speed = $el.data('speed') || 300;
    const usePrevArrow = $el.data('use-prev-arrow') || false;
    const useNextArrow = $el.data('use-next-arrow') || false;
    const dots = $el.data('show-dots') || false;
    const slidesToScroll = $el.data('slides-to-scroll') || 1;

    const prevArrow = usePrevArrow ? '<span class="prev icn icn_cp icf_arrow_left"></span>' : null;

    let nextArrow = null;
    // slickをauto scrollにしている場合のarrowの位置が異なるため↓で対応する
    if (useNextArrow && isAutoCarousel) {
      nextArrow = '<span class="next icn icn_cp icf_arrow_right auto_carousel_next"></span>';
    } else if (useNextArrow) {
      nextArrow = '<span class="next icn icn_cp icf_arrow_right"></span>';
    }

    const showArrows = !!(usePrevArrow && useNextArrow);

    let arrowOptions = { arrows: showArrows };
    if (showArrows) {
      arrowOptions = { ...arrowOptions, prevArrow, nextArrow };
    }

    return {
      arrowOptions,
      dots,
      speed,
      slidesToScroll,
    };
  };

  const $carousels = $('.js-carousel');
  if ($carousels.length > 0) {
    $carousels.each((_, el) => {
      const $carousel = $(el);
      const { arrowOptions, dots, speed, slidesToScroll } = setupCarousel($carousel);

      $carousel.slick({
        dots,
        speed,
        slidesToScroll,
        variableWidth: true,
        infinite: true,
        ...arrowOptions,
      });
    });
  }

  const $autoCarousels = $('.js-carousel-auto');
  if ($autoCarousels.length > 0) {
    const isAutoCarousel = true;
    $autoCarousels.each((_, el) => {
      const $autoCarousel = $(el);

      const { arrowOptions, dots, speed, slidesToScroll } = setupCarousel($autoCarousel, isAutoCarousel);

      $autoCarousel.slick({
        autoplay: true,
        autoplaySpeed: 5000,
        speed,
        dots,
        slidesToScroll,
        useTransform: true,
        infinite: true,
        ...arrowOptions,
      });
    });
  }
});
